<template>
  <div class="myTreees">
    <div class="header">
      <my-treees-header
        :display-name="displayName"
        @clickSignOut="signOut"
      />
    </div>
    <div class="content">
      <div
        class="treeeList"
        v-click-outside="unselectTreee"
      >
        <action-menu
          :treee-is-selected="treeeIsSelected"
          @clickAddNew="showNewTreeeDialog"
          @clickOpen="onClickEdit(selectedTreeeId)"
          @clickRename="showEditTitleDialog"
          @clickDelete="showConfirmDeleteDialog"
        />
        <div class="listHeader">
          <b class="listHeaderName">Name</b>
          <span class="listHeaderDate">Created at</span>
          <span class="listHeaderDate">Updated at</span>
        </div>
        <ul class="items">
          <li
            class="item"
            :class="{'selected': item.id === (treeeIsSelected? selectedTreeeId : '')}"
            v-for="(item, index) in items"
            :key="`item_${index}`"
            @dblclick="onClickEdit(item.id)"
            @click="selectTreee(item.id)"
          >
            <b class="itemName">{{item.title}}</b>
            <span class="itemDate">{{item.createdAt === null? '' : formatDate(item.createdAt.toDate())}}</span>
            <span class="itemDate">{{item.updatedAt === null? '' : formatDate(item.updatedAt.toDate())}}</span>
          </li>
        </ul>

        <my-treees-dialogs
          :dialog-visibility="dialogVisibility"
          :dialog-type="dialogType"
          :treee-is-selected="treeeIsSelected"
          :selected-treee-title="treeeIsSelected? findTreeeById(selectedTreeeId).title : ''"
          @clickHideDialog="hideDialog"
          @clickAddNew="onClickAdd"
          @clickSaveTreeeTitle="onClickSaveTreeeTitle"
          @clickDelete="onClickDeleteTreee"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import dayjs from 'dayjs'
import { createNamespacedHelpers } from 'vuex'
import MyTreeesHeader from '../components/myTreees/organisms/MyTreeesHeader'
import ActionMenu from '../components/myTreees/organisms/ActionMenu'
import MyTreeesDialogs from '../components/myTreees/organisms/MyTreeesDialogs'

const myTreeesStore = createNamespacedHelpers('myTreeesStore')
const authStore = createNamespacedHelpers('authStore')

export default {
  name: 'MyTreees',
  components: {
    MyTreeesHeader,
    ActionMenu,
    MyTreeesDialogs
  },
  data () {
    return {
      dialogVisibility: false,
      dialogType: '',
      selectedTreeeId: '',
      treeeIsSelected: false
    }
  },
  beforeMount () {
    this.subscribeMyTreees()
  },
  destroyed () {
    this.unsubscribeMyTreees()
  },
  computed: {
    ...myTreeesStore.mapState([
      'items'
    ]),
    ...myTreeesStore.mapGetters([
      'findTreeeById'
    ]),
    ...authStore.mapState([
      'userId',
      'displayName'
    ])
  },
  methods: {
    // utilities
    formatDate (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },

    // dialog handlers
    showEditTitleDialog () {
      this.dialogType = 'EDIT_TITLE'
      this.dialogVisibility = true
    },
    onClickSaveTreeeTitle (modifiedTitle) {
      this.hideDialog()
      if (this.findTreeeById(this.selectedTreeeId).title === modifiedTitle) return
      this.updateTreee({
        treeeId: this.selectedTreeeId,
        titleValue: modifiedTitle
      })
    },

    showConfirmDeleteDialog () {
      this.dialogType = 'CONFIRM_DELETE'
      this.dialogVisibility = true
    },
    onClickDeleteTreee () {
      this.hideDialog()
      this.deleteTreee(this.selectedTreeeId)
    },

    showNewTreeeDialog () {
      this.unselectTreee()
      this.dialogType = 'NEW_TREEE'
      this.dialogVisibility = true
    },
    onClickAdd (newTreeeTitle) {
      this.hideDialog()
      const title = newTreeeTitle === '' ? 'untitled' : newTreeeTitle
      this.addNewTreee(title).then(treeeId => {
        window.open(this.$router.resolve({
          name: 'treee_editor',
          params: {
            userId: this.userId,
            treeeId
          }
        }).href, '_blank')
      })
    },

    hideDialog () {
      this.dialogType = ''
      this.dialogVisibility = false
    },

    // my treee list handlers
    selectTreee (treeeId) {
      this.selectedTreeeId = treeeId
      this.treeeIsSelected = true
    },
    unselectTreee () {
      this.selectedTreeeId = ''
      this.treeeIsSelected = false
    },

    // treee item handlers
    onClickEdit (treeeId) {
      this.unselectTreee()
      window.open(this.$router.resolve({
        name: 'treee_editor',
        params: {
          userId: this.userId,
          treeeId
        }
      }).href, '_blank')
      // open with current tab.
      // this.$router.push({
      //   name: 'treee_editor',
      //   params: {
      //     userId: this.userId,
      //     treeeId
      //   }
      // })
    },

    ...myTreeesStore.mapActions([
      'unsubscribeMyTreees',
      'subscribeMyTreees',
      'addNewTreee',
      'deleteTreee',
      'updateTreee'
    ]),
    ...authStore.mapActions([
      'signOut'
    ])
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
  @import "../styles/constants";

  .myTreees {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header {
    flex: 0 0 auto;
    height: 64px;
    box-sizing: border-box;
    border-bottom: solid 1px $stroke;
  }

  .content {
    flex: 1 0 calc(100% - 64px);
    box-sizing: border-box;
    display: inline-flex;
    background-color: white;
  }

  .treeeList {
    width: 920px;
    height: 100%;
    margin: 0 auto;
    padding: 10px 20px 0;
    box-sizing: border-box;
  }

  .items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 80px);
    overflow: auto;
  }

  .item {
    height: 40px;
    border-bottom: solid 1px $stroke;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .item:last-child {
    margin: 0 0 40px;
  }

  .item.selected {
    background-color: $wall;
  }

  .itemName {
    @extend %typo-body1;

    flex: 1 0 auto;
    color: $primary-d;
  }

  .itemDate {
    @extend %typo-caption;

    flex: 0 0 180px;
    text-align: center;
    color: $low-d;
  }

  .listHeader {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    border-bottom: solid 1px $stroke;
  }

  .listHeaderName {
    @extend %typo-subtitle2;

    flex: 1 0 auto;
    color: $secondary-d;
  }

  .listHeaderDate {
    @extend %typo-subtitle2;

    flex: 0 0 180px;
    text-align: center;
    color: $secondary-d;
  }
</style>
