<template>
  <button
    class="raisedPrimaryButton"
    type="button"
    @click="$emit('click')"
  ><slot>button label</slot></button>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import '../../../styles/constants';

  .raisedPrimaryButton {
    @extend %typo-button;

    line-height: 1;
    border: none;
    box-sizing: border-box;
    height: 34px;
    min-width: 80px;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: $theme-a;
    color: $white;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  .raisedPrimaryButton[disabled] {
    background-color: $gray-4;
    color: $gray-3;
    cursor: default;

    &:hover {
      filter: none;
    }
  }
</style>
