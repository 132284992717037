<template>
  <div class="myTreeesHeader">
    <div class="appName">
      <treee-logo class="logo"/>
    </div>
    <div class="nav">
      <span class="displayName">{{displayName}}</span>
      <flat-button @click="$emit('clickSignOut')">sign out</flat-button>
    </div>
  </div>
</template>

<script>
import FlatButton from '../../../components/common/atoms/FlatButton'
import TreeeLogo from '../../../images/TreeeLogo.svg'

export default {
  name: 'MyTreees',
  components: {
    FlatButton,
    TreeeLogo
  },
  props: {
    displayName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../styles/constants";

  .myTreeesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }

  .appName {
    display: flex;
  }

  .logo {
    height: 16px;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .displayName {
    @extend %typo-body1;

    color: $secondary-d;
  }
</style>
