<template>
  <button
    class="flatButton"
    type="button"
    @click="$emit('click')"
  ><slot>button label</slot></button>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
})
</script>

<style scoped lang="scss">
  @import '../../../styles/constants';

  .flatButton {
    @extend %typo-button;

    line-height: 1;
    border: none;
    box-sizing: border-box;
    height: 34px;
    min-width: 80px;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: transparent;
    color: $theme-a;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
      background-color: rgba($theme-a, 0.08);
    }

    &:active {
      filter: brightness(90%);
      background-color: rgba($theme-a, 0.08);
    }
  }

  .flatButton[disabled] {
    color: $gray-3;
    cursor: default;

    &:hover {
      filter: none;
    }
  }
</style>
