<template>
  <button
    class="flatIconButton"
    type="button"
    @click="$emit('click')"
  ><slot>button label</slot></button>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
})
</script>

<style scoped lang="scss">
  @import '../../../styles/constants';

  .flatIconButton {
    font-size: 2.4rem;
    border: none;
    box-sizing: border-box;
    border-radius: 100%;
    height: 34px;
    width: 34px;
    cursor: pointer;
    color: $theme-a;
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
      background-color: rgba($theme-a, 0.08);
    }

    &:active {
      filter: brightness(90%);
      background-color: rgba($theme-a, 0.08);
    }
  }

  .flatIconButton[disabled] {
    color: $gray-4;
    cursor: default;

    &:hover {
      filter: none;
      background-color: transparent;
    }
  }
</style>
