<template>
  <div class="myTreeesDialogs" v-if="dialogVisibility">
    <div class="veil" @click="$emit('clickHideDialog')"></div>

    <div
      class="content"
      v-if="dialogType === 'NEW_TREEE'"
    >
      <div class="header">
        <b class="title">Add new item</b>
      </div>
      <div class="body">
        <p class="description">Input title of new treee.</p>
        <input
          type="text"
          placeholder="enter title"
          v-focus
          ref="newTreeeTitle"
        >
      </div>
      <div class="footer">
        <raised-button-primary
          @click="$emit('clickAddNew', $refs.newTreeeTitle.value)"
        >add</raised-button-primary>
      </div>
    </div>

    <div
      class="content"
      v-if="dialogType === 'EDIT_TITLE'"
    >
      <div class="header">
        <b class="title">Rename item</b>
      </div>
      <div class="body">
        <p class="description">Input new name for "{{selectedTreeeTitle}}".</p>
        <input
          type="text"
          placeholder="enter title"
          v-focus
          ref="modifiedTitle"
          :value="selectedTreeeTitle"
        >
      </div>
      <div class="footer">
        <raised-button-primary
          :disabled="!treeeIsSelected"
          @click="$emit('clickSaveTreeeTitle', $refs.modifiedTitle.value)"
        >rename</raised-button-primary>
      </div>
    </div>

    <div
      class="content"
      v-if="dialogType === 'CONFIRM_DELETE'"
    >
      <div class="header">
        <b class="title">Delete treee?</b>
      </div>
      <div class="body">
        <p class="description">This operation can not undo.</p>
      </div>
      <div class="footer">
        <raised-button-danger
          :disabled="!treeeIsSelected"
          @click="$emit('clickDelete')"
        >delete</raised-button-danger>
      </div>
    </div>
  </div>
</template>

<script>
import RaisedButtonPrimary from '../../../components/common/atoms/RaisedButtonPrimary'
import RaisedButtonDanger from '../../../components/common/atoms/RaisedButtonDanger'

export default {
  name: 'MyTreeesDialogs',
  components: {
    RaisedButtonPrimary,
    RaisedButtonDanger
  },
  props: {
    dialogVisibility: {
      type: Boolean,
      required: true
    },
    dialogType: {
      type: String,
      required: true
    },
    treeeIsSelected: {
      type: Boolean,
      required: true
    },
    selectedTreeeTitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../styles/constants";

  .myTreeesDialogs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
  }

  .veil {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $smoke-1;
    z-index: 301;
  }

  .content {
    background-color: white;
    box-sizing: border-box;
    width: 500px;
    z-index: 302;
  }

  .header {
    padding: 20px 20px 10px;
  }

  .title {
    @extend %typo-h3;

    color: $primary-d;
  }

  .body {
    padding: 10px 20px;
  }

  .description {
    @extend %typo-body1;

    margin: 0 0 10px;
    color: $primary-d;
  }

  .footer {
    padding: 10px 20px 20px;
    display: flex;
    justify-content: flex-end;
  }
</style>
