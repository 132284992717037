<template>
  <div class="actionMenu">
    <button
      class="addNewButton"
      @click="$emit('clickAddNew')"
    >
      <i class="addNewIcon">
        <plus-icon :size="34"/>
      </i>new item
    </button>
    <div class="actionButtons" v-if="treeeIsSelected">
      <flat-icon-button
        @click="$emit('clickOpen')"
      >
        <open-in-new-icon title="Open"/>
      </flat-icon-button>
      <flat-icon-button
        @click="$emit('clickRename')"
      >
        <file-document-edit-icon title="Rename"/>
      </flat-icon-button>
      <flat-icon-button
        @click="$emit('clickDelete')"
      >
        <delete-forever-icon title="Delete"/>
      </flat-icon-button>
    </div>
  </div>
</template>

<script>
import FlatIconButton from '../../../components/common/atoms/FlatIconButton'
import DeleteForeverIcon from 'vue-material-design-icons/DeleteForever'
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew'
import FileDocumentEditIcon from 'vue-material-design-icons/FileDocumentEdit'
import PlusIcon from 'vue-material-design-icons/Plus'

export default {
  name: 'ActionMenu',
  components: {
    FlatIconButton,
    DeleteForeverIcon,
    OpenInNewIcon,
    PlusIcon,
    FileDocumentEditIcon
  },
  props: {
    treeeIsSelected: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../styles/constants";

  .actionMenu {
    box-sizing: border-box;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actionButtons {
    display: flex;
    width: 120px;
    justify-content: space-between;
  }

  .addNewButton {
    @extend %typo-button;

    border: none;
    box-sizing: border-box;
    height: 40px;
    min-width: 80px;
    border-radius: 34px;
    padding: 0 15px 0 5px;
    cursor: pointer;
    background-color: $white;
    color: $secondary-d;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 $smoke-3;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  .addNewIcon {
    font-size: 34px;
    color: $theme-a;
    margin: -8px 5px 0 0;
    height: 34px;
  }
</style>
